import React, { useEffect, useRef } from 'react'
import PlusIcon from '~/client/components/Icons/PlusIcon'
import './styles.css'
import Cookies from 'js-cookie'
import { connect } from 'react-redux'
import { setPopup } from '../../redux/actions/popupsActions'
import {
  addToWatchlist,
  removeFromWatchlist
} from '~/client/redux/actions/userRestActions'

function PlusButton ({
  addToWatchlist,
  removeFromWatchlist,
  watchListoptions,
  isAddToWl,
  attrs,
  user,
  forCarousel,
  setPopup,
  watchListUpdate
}) {
  const btnRef = useRef()

  const lang = Cookies.get('userLanguage') || 'en'

  useEffect(() => {
    const width = window.innerWidth
    if (width < 1400 && width > 1200) {
      btnRef.current.className = `secondary-button carousel-slide-secondary-icon carousel-slide-watchlist-icon plus-button-icon-container ${
        forCarousel ? ' ' : 'responsive-plus-1400'
      } `
    } else if (width < 1200 && width > 1000) {
      btnRef.current.className = `secondary-button carousel-slide-secondary-icon carousel-slide-watchlist-icon plus-button-icon-container ${
        forCarousel ? ' ' : 'responsive-plus-1200'
      } `
    } else if (width < 1000 && width > 900) {
      btnRef.current.className = `secondary-button carousel-slide-secondary-icon carousel-slide-watchlist-icon plus-button-icon-container ${
        forCarousel ? ' ' : 'responsive-plus-1000'
      } `
    } else if (width < 900 && width > 800) {
      btnRef.current.className = `secondary-button carousel-slide-secondary-icon carousel-slide-watchlist-icon plus-button-icon-container ${
        forCarousel ? ' ' : 'responsive-plus-900'
      } `
    }
  })

  const handlerAddAndRemoveFromWatchlist = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (user.auth) {
      !isAddToWl
        ? addToWatchlist(watchListoptions, user)
        : removeFromWatchlist(watchListoptions, user)
    } else {
      setPopup('login')
    }
  }
  return (
    <div className='plus-button-container'>
      <div className='plus-button-wrapper'>
        <div className='plus-button-parent'>
          <button
            className='secondary-button carousel-slide-secondary-icon carousel-slide-watchlist-icon
            plus-button-icon-container
            '
            type='button'
            ref={btnRef}
            {...attrs}
            data-tooltip
            data-tooltip-msg={
              isAddToWl
                ? lang === 'bn'
                  ? 'ওয়াচলিস্ট থেকে সরান'
                  : 'Remove from Watchlist'
                : lang === 'bn'
                  ? 'ওয়াচলিস্টে যোগ করুন'
                  : 'Add to Watchlist'
            }
            onClick={handlerAddAndRemoveFromWatchlist}
          >
            <PlusIcon
              isAddedToWatchlist={isAddToWl}
              {...attrs}
              watchListUpdate={watchListUpdate}
            />
          </button>
        </div>
      </div>
    </div>
  )
}

export default connect(
  (state) => {
    return {
      user: state.user,
      popups: state.popups,
      watchListUpdate: state.user.watchlistUpdate,
      mweb: state.page.mweb
    }
  },
  { setPopup, addToWatchlist, removeFromWatchlist }
)(PlusButton)
