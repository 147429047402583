import React, { useState, useRef, useEffect } from 'react'
import './styles.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Autoplay, Lazy } from 'swiper'
import CarouselSlide from '../CarouselSlide'
import { LOGIN, VIDEOPLAYER, NOTIFIER } from '~/client/redux/popup-types'
import CommonPopup from '~/client/popups/CommonPopup'
import { useNavigate } from 'react-router-dom'
import {
  fetchSeriesWatchHistory,
  fetchVideoDetails,
  getVideoInfo
} from '~/client/apis/videoPlayer'
import { connect } from 'react-redux'

const CarouselSlider = ({
  moduleData,
  mweb,
  user,
  videoplayer,
  setPopup,
  variant = 'simple'
}) => {
  SwiperCore.use([Navigation, Autoplay, Lazy])
  const { contentData: slidesDataArray, metadataMap = false } = moduleData
  //
  const [nextVideo, setNextVideo] = useState(false)
  const [showCommonPopup, setShowCommonPopup] = useState(false)
  const isVideoPlayerRef = useRef(false)
  const carouselRef = useRef(null)
  isVideoPlayerRef.current = videoplayer
  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()

  const onPlayButtonClick = (slideData) => {
    handlePlayClick(slideData, slideData.gist.id, null)
  }

  useEffect(() => {
    setIsLoading(false)
    function fnBrowserDetect () {
      const userAgent = navigator.userAgent
      let browserName
      if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = 'chrome'
      } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = 'firefox'
      } else if (userAgent.match(/safari/i)) {
        browserName = 'safari'
      } else if (userAgent.match(/opr\//i)) {
        browserName = 'opera'
      } else if (userAgent.match(/edg/i)) {
        browserName = 'edge'
      } else {
        browserName = 'No browser detection'
      }
      return browserName
    }
    const data = fnBrowserDetect()

    if ((data === 'safari' || data === 'SAFARI') && carouselRef.current) {
      const nextButton = carouselRef.current.querySelector(
        '.swiper-button-next'
      )
      if (nextButton) {
        nextButton.style.marginRight = '-16px'
      }
    }
    setTimeout(() => {
      carouselRef.current?.swiper?.autoplay?.start()
      carouselRef.current?.swiper?.el.addEventListener('mouseenter', () => {
        carouselRef.current?.swiper?.autoplay?.stop()
      })
      carouselRef.current?.swiper?.el.addEventListener('mouseleave', () => {
        carouselRef.current?.swiper?.autoplay?.start()
      })
    }, 10000)
  }, [])

  useEffect(() => {
    if (carouselRef.current) {
      const prevButton = carouselRef.current.querySelector(
        '.swiper-button-prev'
      )
      const nextButton = carouselRef.current.querySelector(
        '.swiper-button-next'
      )
      if (slidesDataArray.length === 1) {
        if (prevButton && nextButton) {
          prevButton.style.display = 'none'
          nextButton.style.display = 'none'
        }
      } else {
        if (prevButton && nextButton) {
          prevButton.style.display = 'flex'
          nextButton.style.display = 'flex'
        }
      }
    }
  }, [slidesDataArray])

  const handlePlayClick = async (
    slideData,
    videoId,
    trailerId,
    nextEpisode = false
  ) => {
    if (trailerId) {
      const videoId = trailerId
      return fetchVideoDetails(videoId)
        .then((res) => {
          const videoData = res?.data

          if (videoData) {
            setPopup(VIDEOPLAYER, {
              videoId,
              videoData,
              isTrailer,
              isPromo,
              watchedDetails: watchedDetailsRef.current
            })
          } else throw res
        })
        .catch((err) => {
          if (
            err?.response?.data?.errorCode === 'CANT_ACCESS_GEO_BLOCKED_BY_PLAN'
          ) {
            return setShowCommonPopup({
              heading: 'Access Denied',
              subheading:
                'You are accessing Hoichoi from a region that is not the same as your registered account. Please contact the support team to change your region.',
              ctaText: 'Contact our Support Team',
              onCtaClick: () => navigate('/user'),
              onCloseClick: () => setShowCommonPopup(false)
            })
          }
          if (err?.response?.data?.errorCode === 403) {
            setPopup(LOGIN)
          }
          setPopup(NOTIFIER, {
            message: err.response.data.errorMessage
          })
        })
    }
    if (!user?.auth && !isFreeContent(slideData, videoId)) {
      !mweb
        ? isVideoPlayerRef.current
          ? setShowCommonPopup({
            heading: 'Access Denied',
            subheading: 'Please login or subscribe to watch premium content.',
            ctaText: 'Login',
            onCtaClick: () => {
              setShowCommonPopup(false)
              isVideoPlayerRef.current && setPopup(VIDEOPLAYER)
              setPopup(LOGIN)
            },
            onCloseClick: () => setShowCommonPopup(false)
          })
          : setPopup(LOGIN)
        : (isVideoPlayerRef.current && setPopup(VIDEOPLAYER), setPopup(LOGIN))
    } else if (!user?.isSubscribed && !isFreeContent(slideData, videoId)) {
      setShowCommonPopup({
        heading: 'Access Denied',
        subheading: 'Please subscribe to watch premium content.',
        ctaText: 'Subscribe Now',
        onCtaClick: () => {
          navigate('/subscribe')
          setShowCommonPopup(false)
        },
        onCloseClick: () => setShowCommonPopup(false)
      })
    } else {
      if (videoId) {
        try {
          if (slideData?.gist?.contentType === 'SERIES' && !nextEpisode) {
            const seriesWatchHistoryRes = await fetchSeriesWatchHistory(
              slideData?.gist?.id
            )
            seriesWatchHistoryRef.current = seriesWatchHistoryRes?.data
            videoId = seriesWatchHistoryRes?.data[0]?.videoId
              ? seriesWatchHistoryRes?.data[0]?.videoId
              : slideData?.seasons[0]?.episodes[0]?.id
          }
          const getVideoRes = await getVideoInfo(
            videoId || slideData?.seasons[0]?.episodes[0]?.id
          )
          watchedDetailsRef.current = getVideoRes.data

          if (slideData?.gist?.contentType === 'VIDEO') {
            const getVideoRes = await getVideoInfo(slideData.gist.id)
            watchedDetailsRef.current = getVideoRes.data
          }
        } catch (error) {}
        try {
          const videoDetailsRes = await fetchVideoDetails(videoId)
          const videoData = videoDetailsRes?.data
          if (videoData) {
            setPopup(VIDEOPLAYER, {
              videoId,
              videoData,
              isTrailer: videoData.video.gist.isTrailer,
              isPromo: videoData.video.gist.isTrailer,
              watchedDetails: watchedDetailsRef.current,
              s: {
                fileType:
                  (metadataMap && metadataMap.fileType) ||
                  (s && s.fileType) ||
                  'hls'
              }, // we are not getting filetype in case carousel that's why hardcoded.
              ...(slideData?.seasons && {
                handlePlayClick: (videoId) =>
                  handlePlayClick(slideData, videoId, null, true),
                nextEpisodeId: getNextEpisodeId(slideData.seasons, videoId)
              })
            })
          } else throw res
        } catch (err) {
          if (
            err?.response?.data?.errorCode === 'CANT_ACCESS_GEO_BLOCKED_BY_PLAN'
          ) {
            return setShowCommonPopup({
              heading: 'Access Denied',
              subheading:
                'You are accessing Hoichoi from a region that is not the same as your registered account. Please contact the support team to change your region.',
              ctaText: 'Contact our Support Team',
              onCtaClick: () => navigate('/user'),
              onCloseClick: () => setShowCommonPopup(false)
            })
          }

          setPopup(NOTIFIER, {
            message: err.response.data.errorMessage
          })
        }
      }
    }
  }
  const getNextEpisodeId = (seasonsData, currentVideoId) => {
    let nextEpisodeId = ''
    seasonsData.map((season, i) => {
      season.episodes.filter((episode, j) => {
        if (
          episode.id === currentVideoId &&
          !(season.episodes.length - 1 === j)
        ) {
          nextEpisodeId = seasonsData[i].episodes[j + 1].id
        }
      })
    })
    return nextEpisodeId
  }

  const isFreeContent = (slideData, videoId) => {
    const data = slideData
    const { seasons = false } = data
    let isFree = false
    if (seasons) {
      seasons.map((season) => {
        season.episodes.filter((episode) => {
          if (episode.id === videoId) {
            isFree = episode.gist.free
          }
        })
      })
    } else isFree = data.gist.free

    return isFree
  }
  const _slidesDataArray = (slidesDataArray && slidesDataArray) || []

  return (
    <>
      {
        <Swiper
          id='carousel'
          className={'carousel-' + variant}
          navigation={!mweb}
          // autoplay={
          //   !(typeof window === 'undefined' || slidesDataArray.length === 1)
          //     ? { delay: 10000 }
          //     : false
          // }
          ref={carouselRef}
          /* hotstar like carousel */
          slidesPerView={mweb ? 1.1 : 1}
          /* hotstar like carousel */
          centeredSlides={!!mweb}
          /* hotstar like carousel */
          spaceBetween={mweb ? 10 : 0}
          loop
          lazy
          preloadImages={false}
        >
          {_slidesDataArray && isLoading
            ? _slidesDataArray.map((slideData) => {
              return (
                <SwiperSlide key={slideData.id}>
                  <CarouselSlide
                    slideData={_slidesDataArray[0]}
                    key={`${0}-carousel-v2-slide`}
                    mweb={mweb}
                    index={0}
                    totalImages={_slidesDataArray.length}
                    onPlayButtonClick={() => onPlayButtonClick(slideData)}
                    setNextVideo={setNextVideo}
                  />
                </SwiperSlide>
              )
            })
            : _slidesDataArray.map((slideData, index) => {
              return (
                <SwiperSlide key={slideData.id}>
                  <CarouselSlide
                    slideData={slideData}
                    key={`${index}-carousel-v2-slide`}
                    mweb={mweb}
                    index={index}
                    totalImages={_slidesDataArray.length}
                    onPlayButtonClick={() => onPlayButtonClick(slideData)}
                    setNextVideo={setNextVideo}
                  />
                </SwiperSlide>
              )
            })}
        </Swiper>
      }
      {showCommonPopup && <CommonPopup {...showCommonPopup} />}
    </>
  )
}

export default connect((state) => {
  return {
    user: state.user,
    page: state.page,
    videoplayer: state.popups?.videoplayer
  }
})(CarouselSlider)
