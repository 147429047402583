import React, { useEffect, useRef } from 'react'
import '~/client/components/Icons/styles.css'
import PlayIcon2 from '~/client/components/Icons/PlayIcon2'
import './styles.css'

export default function PlayButton({
  onPlayButtonClick,
  disabled = false,
  playCtaText = 'Play Now',
  forCarousel
}) {
  const playRef = useRef()
  useEffect(() => {
    const width = window.innerWidth
    if (width < 1400 && width > 1200) {
      playRef.current.className = `play-button-play-icon-container ${
        forCarousel ? ' ' : 'responsive1400'
      }`
    } else if (width < 1200 && width > 1000) {
      playRef.current.className = `play-button-play-icon-container ${
        forCarousel ? ' ' : 'responsive1200'
      }`
    } else if (width < 1000 && width > 900) {
      playRef.current.className = `play-button-play-icon-container ${
        forCarousel ? ' ' : 'responsive1000'
      }`
    } else if (width < 900 && width > 800) {
      playRef.current.className = `play-button-play-icon-container ${
        forCarousel ? ' ' : 'responsive900'
      }`
    }
  })
  return (
    <button
      className="play-button-play-icon-container"
      ref={playRef}
      disabled={disabled}
      onClick={() => {
        onPlayButtonClick && onPlayButtonClick()
      }}
      // style={{
      //   width: playCtaText === 'Subscribe to watch' ? '210px' : '160px'
      // }}
    >
      {playCtaText !== 'Subscribe to watch' ? <PlayIcon2 /> : null}
      <span style={{ textTransform: 'capitalize' }}>{playCtaText}</span>
    </button>
  )
}
