import React from 'react';

export default function PlayIcon2() {
  return (
    <svg viewBox="0 0 18 20" className="icons">
      <g
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-69.000000, -605.000000)"
          stroke="#FFFFFF"
          strokeWidth={2}
        >
          <g transform="translate(40.000000, 434.000000)">
            <g transform="translate(0.000000, 156.000000)">
              <g transform="translate(30.000000, 15.000000)">
                <path d="M13.8083569,11.7149859 L3.02899151,18.1826051 C2.08183098,18.7509014 0.853310468,18.4437713 0.285014149,17.4966108 C0.0985156667,17.1857799 -1.55426673e-16,16.8301071 0,16.4676192 L0,3.53238076 C-3.57315355e-16,2.42781126 0.8954305,1.53238076 2,1.53238076 C2.36248789,1.53238076 2.71816071,1.63089642 3.02899151,1.81739491 L13.8083569,8.28501415 C14.7555174,8.85331047 15.0626476,10.081831 14.4943513,11.0289915 C14.3254517,11.3104907 14.0898561,11.5460863 13.8083569,11.7149859 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
